<template>
    <div>
        <div class='main-loader' v-if='isLoading'></div>
        <section class="pricing">
            <b-container>
                <!-- Subscription Alert -->
                <b-alert
                    v-if="subscriptionAlert.show"
                    :variant="subscriptionAlert.variant"
                    dismissible
                    @dismissed="subscriptionAlert.show = false"
                >
                    <feather-icon
                        :icon="subscriptionAlert.variant === 'success' ? 'check-circle' : 'alert-circle'"
                        class="mr-1"
                        size="18"
                    />
                    {{ subscriptionAlert.message }}
                </b-alert>

                <!-- Current Plan Status -->
                <div v-if="userDetails?.subscription" class="user-details-card mb-4">
                    <b-card>
                        <b-row>
                            <b-col md="6" class="border-right">
                                <h4 class="d-flex align-items-center">
                                    <feather-icon
                                        icon="PackageIcon"
                                        size="20"
                                        class="mr-1"
                                    />
                                    Current Plan: {{ userDetails.subscription.plan }}
                                </h4>
                                <div class="account-details mt-2">
                                    <div class="d-flex justify-content-between mb-1">
                                        <span class="d-flex align-items-center">
                                            <feather-icon
                                                icon="ActivityIcon"
                                                size="16"
                                                class="mr-1"
                                            />
                                            Status:
                                        </span>
                                        <b-badge :variant="getAccountStatusVariant">
                                         {{ capitalizedStatus }}
                                        </b-badge>
                                    </div>
                                    <div class="d-flex justify-content-between mb-1">
                                        <span class="d-flex align-items-center">
                                            <feather-icon
                                                icon="CalendarIcon"
                                                size="16"
                                                class="mr-1"
                                            />
                                            Billing Period Start:
                                        </span>
                                        <span>{{ formatDate(userDetails.subscription.currentPeriodStart) }}</span>
                                    </div>
                                    <div class="d-flex justify-content-between mb-1">
                                        <span class="d-flex align-items-center">
                                            <feather-icon
                                                icon="CalendarIcon"
                                                size="16"
                                                class="mr-1"
                                            />
                                            Billing Period End:
                                        </span>
                                        <span>{{ formatDate(userDetails.subscription.currentPeriodEnd) }}</span>
                                    </div>
                                    <div v-if="userDetails.subscription.trialEndsAt">
                                        <div class="d-flex justify-content-between">
                                            <span class="d-flex align-items-center">
                                                <feather-icon
                                                    icon="ClockIcon"
                                                    size="16"
                                                    class="mr-1"
                                                />
                                                Trial Ends:
                                            </span>
                                            <span>{{ formatDate(userDetails.subscription.trialEndsAt) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </b-col>
                            <b-col md="6">
                                <h4 class="d-flex align-items-center">
                                    <feather-icon
                                        icon="BarChart2Icon"
                                        size="20"
                                        class="mr-1"
                                    />
                                    Usage
                                </h4>
                                <div class="mt-2">
                                    <h6 class="d-flex align-items-center">
                                        <feather-icon
                                            icon="ImageIcon"
                                            size="16"
                                            class="mr-1"
                                        />
                                        Image Creations:
                                    </h6>
                                    <b-progress
                                        :value="getImageCreationsPercentage"
                                        :variant="getUsageVariant('imageCreations')"
                                        class="mb-2"
                                    />
                                    <small class="d-flex align-items-center">
                                        <feather-icon
                                            icon="HashIcon"
                                            size="14"
                                            class="mr-1"
                                        />
                                        {{ userDetails?.subscription?.usage?.imageCreations || 0 }} / 
                                    {{ userDetails?.subscription?.limits?.imageCreations || 0 }} image creations 
                                    </small>

                                    <h6 class="mt-2 d-flex align-items-center">
                                        <feather-icon
                                            icon="ZapIcon"
                                            size="16"
                                            class="mr-1"
                                        />
                                        Image Processing:
                                    </h6>
                                    <b-progress
                                        :value="getImageProcessingPercentage"
                                        :variant="getUsageVariant('imageProcessing')"
                                        class="mb-2"
                                    />
                                    <small class="d-flex align-items-center">
                                        <feather-icon
                                            icon="HashIcon"
                                            size="14"
                                            class="mr-1"
                                        />
                                        {{ userDetails?.subscription?.usage?.imageProcessing || 0 }} / 
                                        {{ userDetails?.subscription?.limits?.imageProcessing || 0 }} Images Processed
                                    </small>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </div>

                <!-- Subscription Plans Section -->
                <div class="mb-4">
                    <div class="text-center">
                        <h1>Subscription Plans</h1>
                        <div class="divider-red"></div>
                        <!-- <p class="mb-2">Choose a plan based on your requirements, free onboarding offered for all paid plans</p> -->
                    </div>
                </div>
                
                <b-row class="pricing-card justify-content-center match-height">
                    <b-col md="6" lg="4" v-for="(item, index) in membershipDetails" :key="index">
                        <b-card class="text-center">
                            <b-img v-if='item.membership_type_image_url==null' :src="require('@/assets/images/illustration/Pot2.svg')" class="mb-2 membership-default-image" alt="basic svg img" />
                            <b-img v-else :src="item.membership_type_image_url" class="mb-2 membership-default-image" />
                            <h2 class="font-weight-bold text-uppercase text-dark">{{item.membership_type_name}}</h2>
                            <div class="up-pricing">
                                <template v-if="item.membership_type_id === 600">
                                    <strong class="up-price text-uppercase text-primary">$0.10</strong>
                                    <span class="up-month small">per Run</span>
                                </template>
                                <template v-else>
                                    <strong class="up-price text-uppercase text-primary">${{item.membership_price}}</strong>
                                    <span class="up-month small">per Month</span>
                                </template>
                            </div>

                            <b-list-group class="list-group-circle text-left">
                                <b-list-group-item v-for="(benefit, bIndex) in item.benefit_lists" :key="bIndex">
                                    {{benefit.benefit_description}}
                                </b-list-group-item>
                            </b-list-group>

                            <b-button 
    block 
    :variant="getCurrentPlanVariant(item)" 
    class="mt-2"
    :disabled="isButtonDisabled(item)"
    @click="purchaseMembership(item)"
>
    <template v-if="isCurrentPlan(item)">
        <feather-icon
            icon="check-circle"
            size="16"
            class="mr-1"
        />
    </template>
    {{ getPlanButtonText(item) }}
</b-button>
                        </b-card>
                    </b-col>
                </b-row>
            </b-container>
        </section>
         <!-- Cancellation Modal -->
         <b-modal
            id="cancel-subscription-modal"
            title="Cancel Subscription"
            @ok="handleCancelSubscription"
            @cancel="cancelReason = ''"
        >
            <div>
                <p>Please let us know why you're canceling:</p>
                <b-form-textarea
                    v-model="cancelReason"
                    placeholder="Your feedback helps us improve"
                    rows="3"
                ></b-form-textarea>
            </div>
        </b-modal>

<b-modal
    id="upgrade-confirmation-modal"
    title="Upgrade to Business Plan"
    ok-title="Proceed with Upgrade"
    ok-variant="primary"
    cancel-title="Cancel"
    centered
    @ok="handleUpgradeConfirmed"
>
    <div class="text-center mb-2">
        <div class="upgrade-icon mb-2">
            <feather-icon
                icon="TrendingUpIcon"
                size="48"
                class="text-primary"
            />
        </div>
        <h4 class="font-weight-bold">Upgrade to Business Plan</h4>
        <div class="pricing-highlight mt-1 mb-3">
            <h3 class="text-primary mb-0">$99<span class="text-muted h5">/month</span></h3>
        </div>
    </div>

    <div class="upgrade-details">
        <div class="upgrade-step mb-2">
            <div class="d-flex align-items-center">
                <div class="step-number">1</div>
                <div class="step-content">
                    <h6 class="mb-0">Cancel Pro Plan</h6>
                    <small class="text-muted">Your current plan will be cancelled</small>
                </div>
            </div>
        </div>
        
        <div class="upgrade-step mb-2">
            <div class="d-flex align-items-center">
                <div class="step-number">2</div>
                <div class="step-content">
                    <h6 class="mb-0">Activate Business Plan</h6>
                    <small class="text-muted">Your new plan will start immediately</small>
                </div>
            </div>
        </div>

        <div class="features-list mt-3">
            <h6 class="font-weight-bold mb-2">You'll get:</h6>
            <ul class="list-unstyled">
                <li class="mb-2">
                    <feather-icon
                        icon="CheckCircleIcon"
                        size="16"
                        class="text-success mr-2"
                    />
                    <span>250 runs (1,000 image creations)</span>
                </li>
                <li class="mb-2">
                    <feather-icon
                        icon="CheckCircleIcon"
                        size="16"
                        class="text-success mr-2"
                    />
                    <span>20 custom agent creations</span>
                </li>
                <li class="mb-2">
                    <feather-icon
                        icon="CheckCircleIcon"
                        size="16"
                        class="text-success mr-2"
                    />
                    <span>50 expert-crafted images</span>
                </li>
            </ul>
        </div>
    </div>

    <template #footer="{ ok, cancel, hide }">
        <div class="w-100">
            <b-button
                variant="primary"
                block
                @click="ok()"
                class="mb-2"
            >
                <feather-icon
                    icon="CreditCardIcon"
                    size="16"
                    class="mr-1"
                />
                Proceed with Upgrade ($99/month)
            </b-button>
            <b-button
                variant="outline-secondary"
                block
                @click="cancel()"
            >
                Cancel
            </b-button>
        </div>
    </template>
</b-modal>
    </div>
</template>

<script>
import {
    BFormCheckbox,
    BContainer,
    BRow,
    BCol,
    BCard,
    BImg,
    BListGroup,
    BListGroupItem,
    BButton,
    BBadge,
    BAvatar,
    BProgress,
    BProgressBar,
    BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapState, mapActions } from 'vuex'
import BillingService from '@/services/billingService'
import axios from 'axios'
export default {
    name: 'PricingPage',

    metaInfo() {
    const pageTitle = 'Simple & Transparent Pricing | BRIKS.AI';
    const pageDescription = 'Choose your BRIKS.AI plan. Transform product photos into professional marketing visuals starting free. Scale up to 1,000 images/month with dedicated support.';
    const pageUrl = 'https://briks.ai/plans';
    const pageImage = 'https://media.briks.ai/images/image-70dd3a5b-973e-4694-9694-71f0826416d9.jpg';

    return {
      title: pageTitle,
      titleTemplate: '%s | BRIKS.AI',
      meta: [
        // Primary meta tags
        { name: 'description', content: pageDescription },
        { name: 'keywords', content: 'AI photo pricing, product photography plans, BRIKS.AI pricing, marketing visuals subscription' },
        
        // OpenGraph tags
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: pageUrl },
        { property: 'og:title', content: pageTitle },
        { property: 'og:description', content: pageDescription },
        { property: 'og:image', content: pageImage },
        
        // Twitter tags
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:url', content: pageUrl },
        { name: 'twitter:title', content: pageTitle },
        { name: 'twitter:description', content: pageDescription },
        { name: 'twitter:image', content: pageImage }
      ],
      link: [
        { rel: 'canonical', href: pageUrl }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "PriceSpecification",
            "name": "BRIKS.AI Pricing Plans",
            "description": pageDescription,
            "url": pageUrl,
            "offers": [
              {
                "@type": "Offer",
                "name": "Starter",
                "description": "Perfect for trying out BRIKS.AI with 40 monthly image creations",
                "price": "0",
                "priceCurrency": "USD",
                "availability": "https://schema.org/InStock",
                "benefits": [
                  "40 image creations",
                  "Unlimited social media content creation",
                  "Create 1 AI models to generate lifestyle and photoshoot images",
                  "Basic email support"
                ]
              },
              {
                "@type": "Offer",
                "name": "Creator Pro",
                "description": "Professional plan with 400 monthly images and priority features",
                "price": "49",
                "priceCurrency": "USD",
                "availability": "https://schema.org/InStock",
                "benefits": [
                  "400 image creations",
                  "Unlimited social media content creation",
                  "Create 5 AI models to generate lifestyle and photoshoot images",
                  "Priority processing",
                  "Early access to new AI models"
                ]
              },
              {
                "@type": "Offer",
                "name": "Creator Business",
                "description": "Enterprise-grade solution with 1,000 monthly images and dedicated support",
                "price": "99",
                "priceCurrency": "USD",
                "availability": "https://schema.org/InStock",
                "benefits": [
                  "1,000 image creations",
                  "Unlimited social media content creation",
                  "Create 20 AI models to generate lifestyle and photoshoot images",
                  "Dedicated account manager",
                  "Priority processing"
                ]
              }
            ],
            "mainEntityOfPage": {
              "@type": "WebPage",
              "breadcrumb": {
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "BRIKS.AI",
                    "item": "https://briks.ai"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Pricing",
                    "item": "https://briks.ai/plans"
                  }
                ]
              }
            }
          }
        }
      ]
    };
  },

    components: {
        BFormCheckbox,
        BContainer,
        BButton,
        BListGroup,
        BListGroupItem,
        BRow,
        BCol,
        BCard,
        BBadge,
        BImg,
        BAvatar,
        BProgress,
        BProgressBar,
        BAlert
    },
    directives: {
        Ripple,
    },

    data() {
        return {
            isLoading: false,
            userDetails: null,
            membershipDetails: [],subscriptionAlert: {
                show: false,
                variant: 'info',
                message: ''
            },
            cancelReason: '',
            subscriptionAlert: {
            show: false,
            variant: 'info',
            message: ''
            },
            selectedUpgradePlan: null,
            planLimits: {
                'starter': 10,
                'professional': 100,
                'business': 250
            }
        }
    },

    async created() {
    await this.initializeComponent();
    
    // Handle Stripe redirect
    const sessionId = this.$route.query.session_id;
    if (sessionId) {
        await this.handleStripeRedirect(sessionId);
    }
    },

    computed: {
        getUserInitials() {
            if (!this.userDetails?.userName) return '';
            return this.userDetails.userName
                .split(' ')
                .map(name => name[0])
                .join('')
                .toUpperCase();
        },
        getAccountStatusVariant() {
        const status = this.userDetails?.subscription?.status;  // Updated path
        const variants = {
            active: 'success',
            trialing: 'warning',  // Added trialing status
            suspended: 'danger',
            pending: 'warning',
            canceled: 'danger',
            cancelling: 'danger'
        };
        return variants[status] || 'primary';
    },
    capitalizedStatus() {
      if (!this.userDetails?.subscription?.status) return ''
      const status = this.userDetails.subscription.status
      return status.charAt(0).toUpperCase() + status.slice(1)
    },
    getUsagePercentage() {
    // First check if we have all required properties
    if (!this.userDetails?.subscription?.usage?.imageCreations || 
        !this.userDetails?.subscription?.limits?.imageCreations) {
        return 0;
    }

    try {
        const usage = this.userDetails.subscription.usage.imageCreations || 0;
        const limit = this.userDetails.subscription.limits.imageCreations || 1; // prevent division by zero
        return Math.min(Math.round((usage / limit) * 100), 100);
    } catch (error) {
        console.error('Error calculating usage percentage:', error);
        return 0;
    }
},
getImageCreationsPercentage() {
        if (!this.userDetails?.subscription?.usage?.imageCreations || 
            !this.userDetails?.subscription?.limits?.imageCreations) {
            return 0;
        }

        try {
            const usage = this.userDetails.subscription.usage.imageCreations || 0;
            const limit = this.userDetails.subscription.limits.imageCreations || 1;
            return Math.min(Math.round((usage / limit) * 100), 100);
        } catch (error) {
            console.error('Error calculating image creations percentage:', error);
            return 0;
        }
    },

    getImageProcessingPercentage() {
        if (!this.userDetails?.subscription?.usage?.imageProcessing || 
            !this.userDetails?.subscription?.limits?.imageProcessing) {
            return 0;
        }

        try {
            const usage = this.userDetails.subscription.usage.imageProcessing || 0;
            const limit = this.userDetails.subscription.limits.imageProcessing || 1;
            return Math.min(Math.round((usage / limit) * 100), 100);
        } catch (error) {
            console.error('Error calculating image processing percentage:', error);
            return 0;
        }
    },

    getUsageVariant() {
        return (type) => {
            try {
                const percentage = type === 'imageCreations' ? 
                    this.getImageCreationsPercentage : 
                    this.getImageProcessingPercentage;
                
                if (percentage >= 90) return 'danger';
                if (percentage >= 75) return 'warning';
                return 'primary';
            } catch (error) {
                console.error(`Error getting usage variant for ${type}:`, error);
                return 'primary';
            }
        };
    }
    },

    methods: {
        ...mapActions(['setUserData']),

        async initializeComponent() {
            try {
                this.isLoading = true
                await this.initializeUserDetails()
                await this.initializeMembershipDetails()
                await this.checkSubscriptionStatus()
            } catch (error) {
                this.handleError(error)
            } finally {
                this.isLoading = false
            }
        },

        initializeUserDetails() {
    const userData = localStorage.getItem("userData");
    if (userData) {
        try {
            const parsed = JSON.parse(userData);
            if (parsed.userId) {
                this.userDetails = parsed;
                console.log('Initialized User Details:', this.userDetails);
            }
        } catch (error) {
            console.error('Error parsing userData:', error);
        }
    }
    },
        initializeMembershipDetails() {
            this.membershipDetails = [
                {
                    membership_type_id: 'starter',
                    membership_type_name: "Starter",
                    membership_price: "0",
                    membership_type_image_url: require('@/assets/images/illustration/Pot1.svg'),
                    benefit_lists: [
                        { benefit_description: "40 image creations" },
                        { benefit_description: "Unlimited social media content creation" },
                        { benefit_description: "Create 1 AI model to generate lifestyle and photoshoot images" },
                        { benefit_description: "Product video creation (Coming soon)" },
                        { benefit_description: "One-click social media posting (Coming soon)" },
                        { benefit_description: "Basic email support" },
                    ]
                },
                {
                    membership_type_id: 'professional',
                    membership_type_name: "Creator Pro",
                    membership_price: "49",
                    membership_type_image_url: require('@/assets/images/illustration/Pot2.svg'),
                    benefit_lists: [
                        { benefit_description: "400 image creations" },
                        { benefit_description: "Unlimited social media content creation" },
                        { benefit_description: "Create 5 AI models to generate lifestyle and photoshoot images" },
                        { benefit_description: "20 high quality images crafted by expert designers" },
                        { benefit_description: "Priority processing" },
                        { benefit_description: "Early access to new AI models" },
                        { benefit_description: "Product video creation (Coming soon)" },
                        { benefit_description: "One-click social media posting (Coming soon)" },
                    ]
                },
                {
                    membership_type_id: 'business',
                    membership_type_name: "Creator business",
                    membership_price: "99",
                    membership_type_image_url: require('@/assets/images/illustration/Pot3.svg'),
                    benefit_lists: [
                        { benefit_description: "1,000 image creations" },
                        { benefit_description: "Unlimited social media content creation" },
                        { benefit_description: "Create 20 AI models to generate lifestyle and photoshoot images" },
                        { benefit_description: "50 high quality images crafted by expert designers" },
                        { benefit_description: "Dedicated account manager" },
                        { benefit_description: "Dedicated onboarding support" },
                        { benefit_description: "Priority processing" },
                        { benefit_description: "Early access to new AI models" },
                        { benefit_description: "Product video creation (Coming soon)" },
                        { benefit_description: "One-click social media posting (Coming soon)" },
                    ]
                }
            ];
        },

        async checkSubscriptionStatus() {
        if (!this.userDetails?.userId) return;

        try {
            const response = await BillingService.getSubscriptionStatus(this.userDetails.userId);
            console.log('Subscription status response:', response);
            
            if (response.success && response.status) {
                // Update subscription data in userDetails
                this.userDetails = {
                    ...this.userDetails,
                    subscription: {
                        ...response.status,
                        // Ensure plan is properly set
                        plan: response.status.planId || response.status.plan
                    }
                };
                
                // Update localStorage
                localStorage.setItem('userData', JSON.stringify(this.userDetails));
                console.log('Updated user details:', this.userDetails);
            }
        } catch (error) {
            console.error('Error checking subscription status:', error);
            this.handleError(error);
        }
    },

    async showProBusinessUpgradeConfirmation(plan) {
        try {
            this.$bvModal.show('upgrade-confirmation-modal');
        } catch (error) {
            this.handleError(error);
        }
    },


    async handleProToBusinessUpgrade(plan) {
        try {
            // Store the selected plan before showing modal
            this.selectedUpgradePlan = plan;
            this.$bvModal.show('upgrade-confirmation-modal');
        } catch (error) {
            this.handleError(error);
        }
    },

    async handleUpgradeConfirmed() {
    if (!this.selectedUpgradePlan) {
        this.showAlert('danger', 'No plan selected for upgrade');
        return;
    }

    try {
        this.isLoading = true;
        this.$bvModal.hide('upgrade-confirmation-modal');

        // Show processing state
        this.showAlert('info', 'Processing your upgrade request...');

        const currentPlan = this.userDetails.subscription?.plan?.toLowerCase();
        const targetPlan = this.selectedUpgradePlan.membership_type_id.toLowerCase();
        
        const response = await BillingService.upgradePlan(
            this.userDetails.userId,
            this.selectedUpgradePlan.membership_type_id
        );

        if (response.requiresPayment) {
            // Store upgrade intent with current plan info
            localStorage.setItem('upgradeIntent', JSON.stringify({
                fromPlan: currentPlan,
                toPlan: targetPlan,
                timestamp: new Date().toISOString(),
                previousPlanCancelled: true
            }));

            // Redirect to payment
            window.location.href = response.checkoutUrl;
        } else {
            // Handle immediate upgrade success
            this.handleSuccessfulUpgrade(response);
            this.showAlert('success', `Successfully upgraded to ${this.selectedUpgradePlan.membership_type_name}!`);
        }
    } catch (error) {
        this.handleError(error);
        // Show specific error message
        this.showAlert(
            'danger', 
            'Unable to process upgrade. Please try again or contact support.'
        );
    } finally {
        this.isLoading = false;
        this.selectedUpgradePlan = null; // Clear the selected plan
    }
},

    // Update the stripe redirect handler
    async handleStripeRedirect(sessionId) {
        try {
            // Get the upgrade intent
            const upgradeIntent = localStorage.getItem('upgradeIntent');
            
            if (upgradeIntent) {
                const intent = JSON.parse(upgradeIntent);
                // Verify the session and complete upgrade
                const response = await BillingService.verifyUpgradeSession(
                    sessionId,
                    intent
                );

                if (response.success) {
                    // Clear the upgrade intent
                    localStorage.removeItem('upgradeIntent');
                    
                    // Update local state
                    this.handleSuccessfulUpgrade(response);
                    
                    // Clear URL parameters
                    this.$router.replace({ query: null });
                    
                    // Show success message
                    this.showAlert('success', 'Successfully upgraded to Business Plan!');
                }
            }
        } catch (error) {
            this.handleError(error);
            this.showAlert(
                'danger', 
                'Unable to verify upgrade. Please contact support if you were charged.'
            );
        }
    },

    // Helper method to show user-friendly alerts
    showAlert(variant, message) {
        this.subscriptionAlert = {
            show: true,
            variant,
            message
        };

        // Auto-hide success/info alerts after 5 seconds
        if (variant === 'success' || variant === 'info') {
            setTimeout(() => {
                if (this.subscriptionAlert.message === message) {
                    this.subscriptionAlert.show = false;
                }
            }, 5000);
        }
    },

    async purchaseMembership(plan) {
        if (!this.userDetails) {
            this.$bvModal.show("modal-login");
            return;
        }

        const currentPlan = this.userDetails.subscription?.plan?.toLowerCase();
        const targetPlan = plan.membership_type_id.toLowerCase();

        // If switching to free plan
        if (targetPlan === 'starter') {
            // Show confirmation modal
            this.$bvModal.msgBoxConfirm('Are you sure you want to cancel your current plan and switch to the free plan?', {
                title: 'Confirm Cancellation',
                okVariant: 'danger',
                okTitle: 'Yes, Cancel & Switch',
                cancelTitle: 'No, Keep Current Plan'
            }).then(value => {
                if (value) {
                    this.handleCancelAndSwitchToFree();
                }
            });
            return;
        }

        if (currentPlan === 'professional' && targetPlan === 'business') {
            await this.handleProToBusinessUpgrade(plan);
            return;
        }

        // For upgrades
        if (currentPlan === 'starter' || 
            (currentPlan === 'professional' && targetPlan === 'business')) {
            try {
                this.isLoading = true;
                const response = await BillingService.upgradePlan(
                    this.userDetails.userId,
                    plan.membership_type_id
                );

                if (response.requiresPayment) {
                    window.location.href = response.checkoutUrl;
                } else {
                    this.handleSuccessfulUpgrade(response);
                }
            } catch (error) {
                this.handleError(error);
            } finally {
                this.isLoading = false;
            }
            return;
        }
        

        // For downgrades (should be disabled, but just in case)
        this.showAlert('info', 'Please contact support to downgrade your plan.');
    },

    async handleCancelAndSwitchToFree() {
        try {
            this.isLoading = true;
            const response = await BillingService.cancelSubscription(this.userDetails.userId);
            if (response.success) {
                this.showAlert('success', 'Successfully switched to free plan');
                await this.checkSubscriptionStatus();
            }
        } catch (error) {
            this.handleError(error);
        } finally {
            this.isLoading = false;
        }
    },

        async handleStripeRedirect(sessionId) {
            try {
                // Verify the session and update subscription
                const response = await axios.get(`/api/billing/subscription/verify-session/${sessionId}`)
                if (response.data.success) {
                    this.handleSuccessfulUpgrade(response.data)
                    // Clear the session_id from URL
                    this.$router.replace({ query: null })
                }
            } catch (error) {
                this.handleError(error)
            }
        },

        handleSuccessfulUpgrade(data) {
    this.showAlert('success', 'Subscription updated successfully!')
    if (data.subscription) {
        this.userDetails = {
            ...this.userDetails,
            subscription: data.subscription,
            plan: data.subscription.planId  // Update plan field as well
        }
        localStorage.setItem('userData', JSON.stringify(this.userDetails))
    }
},

async checkUsageLimit(operation) {
    const usage = await BillingService.getUsageDetails(this.userDetails.userId);
    if (usage.percentage >= 100) {
        throw new Error('Usage limit reached');
    }
    if (usage.percentage >= 90) {
        this.showUsageWarning();
    }
},

        async handleCancelSubscription() {
            try {
                this.isLoading = true
                const response = await axios.post('/api/billing/subscription/cancel', {
                    userId: this.userDetails.userId,
                    reason: this.cancelReason
                })

                if (response.data.success) {
                    this.showAlert('info', 'Subscription cancelled successfully')
                    await this.checkSubscriptionStatus()
                }
            } catch (error) {
                this.handleError(error)
            } finally {
                this.isLoading = false
                this.cancelReason = ''
            }
        },

        showAlert(variant, message) {
            this.subscriptionAlert = {
                show: true,
                variant,
                message
            }
        },

        handleError(error) {
            console.error('Error:', error)
            this.showAlert(
                'danger',
                error.response?.data?.error || 'An unexpected error occurred'
            )
        },

        formatDate(date) {
            return new Date(date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            })
        },

        isCurrentPlan(plan) {
        if (!this.userDetails?.subscription) return false;
        const currentPlan = this.userDetails.subscription.plan?.toLowerCase();
        return currentPlan === plan.membership_type_id.toLowerCase();
    },
    getCurrentPlanVariant(plan) {
        if (!this.userDetails?.subscription) {
            return 'primary';
        }
        
        if (this.isCurrentPlan(plan)) {
            const status = this.userDetails.subscription.status?.toLowerCase();
            
            switch(status) {
                case 'trialing':
                    return 'warning';
                case 'active':
                    return 'success';
                case 'cancelled':
                    return 'danger';
                default:
                    return 'success';
            }
        }
        
        return 'primary';
    },

    getPlanButtonText(plan) {

        if (!this.userDetails) {
                return 'Start Free';
            }
            
        if (!this.userDetails?.subscription) {
            return plan.membership_price === '0' ? 'Start Free' : 'Choose Plan';
        }

        // If this is current plan
        if (this.isCurrentPlan(plan)) {
            return 'Current Plan';
        }

        const currentPlan = this.userDetails.subscription.plan?.toLowerCase();
        const targetPlan = plan.membership_type_id.toLowerCase();

        // If trying to move to free plan (starter)
        if (targetPlan === 'starter') {
            return 'Cancel & Switch to Free';
        }

        // If on free plan, or upgrading from pro to business
        if (currentPlan === 'starter' || 
            (currentPlan === 'professional' && targetPlan === 'business')) {
            return 'Upgrade Plan';
        }

        // For all other cases (trying to downgrade), show disabled button
        return 'Contact Support to Downgrade';
    },

    getPlanPrice(planId) {
        if (!planId) return 0;
        const plan = this.membershipDetails.find(p => 
            p.membership_type_id.toLowerCase() === planId.toLowerCase()
        );
        return plan ? parseFloat(plan.membership_price) : 0;
    },

    isButtonDisabled(plan) {
        if (!this.userDetails?.subscription) return false;
        if (this.isCurrentPlan(plan)) return true;

        const currentPlan = this.userDetails.subscription.plan?.toLowerCase();
        const targetPlan = plan.membership_type_id.toLowerCase();

        // Disable downgrade buttons except for free plan
        if (targetPlan !== 'starter') {
            const currentPrice = this.getPlanPrice(currentPlan);
            const targetPrice = this.getPlanPrice(targetPlan);
            return currentPrice > targetPrice;
        }

        return false;
    },
}
    
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';


.divider-red {
    height: 3px;
    width: 100px;
    background-color: #DC123E;
    margin: 15px auto;  // Centers the divider and adds spacing
}

.user-details-card {
    margin: 0rem 0;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    
    .stat-card {
        transition: all 0.3s ease;
        &:hover {
            transform: translateY(-5px);
        }
    }

    .border-right {
        border-right: 1px solid #ebe9f1;
    }

    .subscription-alert {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
    min-width: 300px;
}

.usage-warning {
    color: #ed4c78;
    font-size: 0.875rem;
    margin-top: 0.5rem;
}

    .account-details {
        background-color: #f8f8f8;
        border-radius: 0.5rem;
        padding: 1.5rem;

        small {
            font-size: 0.875rem;
        }

        p {
            font-size: 1rem;
            font-weight: 500;
        }
    }
}

.bg-light-primary {
    background-color: rgba(115, 103, 240, 0.12) !important;
}

.bg-light-success {
    background-color: rgba(40, 199, 111, 0.12) !important;
}

.bg-light-info {
    background-color: rgba(0, 207, 232, 0.12) !important;
}

.pricing-card {
    margin-top: 2rem;
    
    .membership-default-image {
        height: 135px;
    }

    .membership-image {
        height: 256px;
        width: 100%;
        object-fit: cover;
    }
}
h6 {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    color: #6e6b7b;
}

.progress {
    height: 0.857rem;
    margin-bottom: 0.5rem;
}

.upgrade-icon {
    display: inline-block;
    padding: 1rem;
    border-radius: 50%;
    background-color: rgba(115, 103, 240, 0.12);
}

.step-number {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: rgba(115, 103, 240, 0.12);
    color: #7367f0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-right: 1rem;
}

.upgrade-step {
    padding: 0.75rem;
    border-radius: 0.5rem;
    background-color: #f8f8f8;
}

.features-list {
    ul li {
        display: flex;
        align-items: center;
    }
}

.pricing-highlight {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: rgba(115, 103, 240, 0.08);
    display: inline-block;
}

small {
    color: #6e6b7b;
    display: block;
    margin-bottom: 1rem;
}

// Add some responsive padding adjustments
@media (max-width: 768px) {
    .user-details-card {
        .border-right {
            border-right: none;
            border-bottom: 1px solid #ebe9f1;
            margin-bottom: 1.5rem;
            padding-bottom: 1.5rem;
        }
    }
}
</style>